<template>
  <div>
    <vb-error-404 />
  </div>
</template>
<script>
import VbError404 from '@/@vb/components/Errors/404'
export default {
  components: {
    VbError404,
  },
}
</script>
